import { roles } from "../config/roles";
export const menuItems = [
  {
    id: 1,
    label: "menuitems.dashboard.text",
    icon: "ri-dashboard-line",
    link: "/",
    permissions: roles.all,
  },
  {
    id: 2,
    label: "menuitems.project.text",
    icon: "ri-building-4-fill",
    permissions: roles.projects,
    subItems: [
      {
        id: 200,
        label: "menuitems.project.all",
        link: "/projects",
      },
      {
        id: 201,
        label: "menuitems.project.top",
        link: "/top-projects",
      },
      {
        id: 201,
        label: "menuitems.project.pending",
        link: "/pending-projects-approval",
      },
    ],
  },

  {
    id: 3,
    label: "menuitems.users.text",
    icon: "ri-user-line",
    link: "/users",
    permissions: roles.users,
  },
  {
    id: 4,
    label: "menuitems.companies.text",
    icon: "ri-building-2-line",
    permissions: roles.companies,
    subItems: [
      {
        id: 401,
        label: "menuitems.companies.all",
        link: "/companies",
      },
      {
        id: 402,
        label: "Company Categories",
        link: "/company_categories",
        permissions: roles.companyCategories,
      },
    ],
  },
  {
    id: 14,
    label: "menuitems.levels.text",
    icon: "ri-user-line",
    link: "/levels",
    permissions: roles.levels,
  },
  // {
  //   id: 6,
  //   label: "menuitems.awards.text",
  //   icon: "ri-award-fill",
  //   permissions: roles.awards,
  //   subItems: [
  //     {
  //       id: 600,
  //       label: "menuitems.awards.list.awards-projects",
  //       link: "/awards-projects",
  //     },
  //     {
  //       id: 601,
  //       label: "menuitems.awards.list.awards-users",
  //       link: "/awards-users",
  //     },
  //     {
  //       id: 602,
  //       label: "menuitems.awards.list.awards-companies",
  //       link: "/awards-companies",
  //     },
  //   ],
  // },
  {
    id: 7,
    label: "menuitems.my-company.text",
    icon: "ri-building-2-line",
    link: "/my-company",
    permissions: roles.myCompany,
  },
  {
    id: 8,
    label: "Claims",
    icon: "ri-auction-line",
    link: "/claims",
    permissions: roles.claims,
  },
  {
    id: 9,
    label: "Enquiry",
    icon: "ri-headphone-line",
    link: "/enquiry",
    permissions: roles.support,
  },
  // {
  //   id: 10,
  //   label: "Support Request",
  //   icon: "ri-user-voice-line",
  //   link: "/support-request",
  //   permissions: roles.supportRequest,
  // },
  {
    id: 11,
    label: "Reported Issues",
    icon: "ri-user-voice-line",
    link: "/reported-issues",
    permissions: roles.reportedIssues,
  },
  {
    id: 101,
    label: "Subscriptions",
    icon: "far fa-money-bill-alt",
    link: "/subscriptions",
    permissions: roles.subscriptions,
  },
  {
    id: 12,
    label: "Project Status",
    icon: "ri-user-voice-line",
    link: "/project-status",
    permissions: roles.projectStatus,
  },
  {
    id: 5,
    icon: "ri-settings-line",
    label: "menuitems.settings.text",
    permissions: roles.settings,
    subItems: [
      {
        id: 500,
        label: "menuitems.settings.list.countries",
        link: "/countries",
      },
      {
        id: 500,
        label: "Currencies",
        link: "/currencies",
      },
      {
        id: 501,
        label: "menuitems.settings.list.certificates",
        link: "/certificates",
      },
      {
        id: 502,
        label: "menuitems.settings.list.company-status",
        link: "/company-status",
      },
      {
        id: 503,
        label: "menuitems.settings.list.sliders",
        link: "/sliders",
      },
      {
        id: 504,
        label: "Architecture Style",
        link: "/property-types",
      },
      {
        id: 505,
        label: "User Professions",
        link: "/user-professions",
      },
      {
        id: 506,
        label: "Reset password",
        link: "/change-password",
      },
      {
        id: 508,
        label: "Configurations",
        link: "/system-config",
      },
    ],
  },
  {
    id: 13,
    label: "Plans",
    icon: "ri-calendar-fill",
    link: "/plans",
    permissions: roles.projectStatus,
  },
];
