// This is for roles
export default {
  all: ["admin", "user"],
  dashboard: ["admin"],
  projects: ["admin"],
  users: ["admin"],
  companies: ["admin"],
  companyCategories: ["admin"],
  settings: ["admin"],
  awards: ["admin"],
  myCompany: ["User"],
  claims: ["admin"],
  levels: ["admin"],
  support: ["admin"],
  supportRequest: ["admin", "user"],
  reportedIssues: ["admin"],
  subscriptions: ["admin"],
  reportedImage: ["admin"],
  projectStatus: ["admin"],
};
